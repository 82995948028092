<!--
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-07-30 18:05:27
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:43:38
-->
<template>
  <div class="visual-panel mixin-hover" :style="{background:background}">
    <div class="panel-title">
      <!-- 左侧内容 -->
      <section v-if="left" class="panel-left flex">
        <slot name="left"></slot>
        <span>{{ title }}</span>
      </section>
      <section v-else class="panel-left flex"></section>

      <!-- 右侧内容 -->
      <section v-if="right" class="panel-right">
        <slot name="right"></slot>
      </section>

      <div class="line-light"></div>
    </div>

    <!-- 底部内容 -->
    <section v-if="center" class="panel-center">
      <slot name="center"></slot>
    </section>
  </div>
</template>
<script>
export default {
  name: 'VisualPanel',
  props: {
    left: {
      type: Boolean,
      default: true
    },
    right: {
      type: Boolean,
      default: true
    },
    center: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '动态标题'
    },
    background: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped>
.visual-panel {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(30px);
  padding: 0px 14px;
  box-sizing: border-box;
  .panel-title {
    height: 34px;
    // font-family: "title";
    // font-style: normal;
    // font-weight: 500;
    font-size: 18px;
    color: #ebf5f5;
    text-shadow: 0px 2px 5px #0088c7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: 15px;
    box-sizing: border-box;
    // font-weight: 700;
    border-bottom: 1px solid rgba(213, 214, 232, 0.3);
    position: relative;
    .line-light {
      width: 105px;
      height: 1px;
      position: absolute;
      left: 0px;
      bottom: -1px;
      background: linear-gradient(90deg, #0081ff 0%, #65fcff 100%);
      box-shadow: 0px 0px 5px #0088c7;
    }
    // background: url(~@/assets/images/index/line-light.png) left bottom no-repeat;

    // background-size: 100% auto;
    .panel-left {
      align-items: center;
      img {
        margin-right: 4px;
        vertical-align: middle;
      }
      span{
        font-family: H_Black;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #EBF5F5;
        letter-spacing: 2px;
        text-shadow: 0px 2px 5px #0088C7;
      }
    }
  }
}
</style>
