/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-07-12 13:49:10
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:15:11
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './public-path'
import store from './store'
import i18n from './lang' // Internationalization
import '@/packages'
import '@/filters'
import './router/permission' // 权限
// import echarts from 'echarts'
import * as echarts from 'echarts'

// 全局使用bus
import Bus from './util/Bus'

import 'animate.css'

// 实现无缝滚动
import vueSeamlessScroll from 'vue-seamless-scroll'
Vue.use(vueSeamlessScroll)

Vue.config.productionTip = false

Vue.prototype.$echarts = echarts

Vue.prototype.$bus = Bus

let instance = null

function render(props = {}) {
  const {
    container
  } = props
  instance = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
    // 开启沙箱模式,如果是 采用 render 模式会报错 ,固选择container 模式
  }).$mount(container ? container.querySelector('#smart-3d') : '#smart-3d')
}

if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export async function bootstrap() {
  console.log('vue app bootstraped')
}

export async function mount(props) {
  // props 包含主应用传递的参数  也包括为子应用 创建的节点信息
  console.log('props from main app', props)
  render(props)
}

export async function unmount() {
  instance.$destroy()
  instance = null
  instance.$el.innerHTML = ''
  router = null
}
